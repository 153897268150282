export const environment = {
	firebase: {},
	vapidKey: '',
	production: false,
	inAws: true,
	baseHostName: 'dev.weaz.net',
	wsPath: '/ws',
	apiPath: '/api',
	awsRegion: 'us-west-2',
	cognitoUserpoolId: 'us-west-2_Wr3AH1ZL0',
	cognitoWebclientId: 'c6nt90grqdd8d0n4af2huk4js',
	cognitoDomain: 'https://auth.dev.weaz.net',
	businessName: 'Example Corp.',
	gitHash: 'b19d22',
};
